<template>
  <div>
    <h1>Voorbeeld</h1>
  </div>
</template>

<script>

export default {
  name: 'Voorbeeld',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
